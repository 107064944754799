import React, { useState } from 'react';
import { useAuth } from '../context/AuthContext'; // Import the useAuth hook
import { useNavigate } from 'react-router-dom'; // Import useNavigate hook
import '../ui/Conversations.css'; // Import the Conversations CSS file
import { Message } from '../types/Message'; // Import the Message type
import { Friend } from '../types/Friend'; // Import the Friend type
import api from '../api/ApiHandler'; // Import the API handler
import { useEffect } from 'react';

const Conversations: React.FC = () => {
    const { user } = useAuth(); // Get user data from context
    const navigate = useNavigate(); // Hook for navigation
    const [conversations, setConversations] = useState<any[]>([]); // State to store conversations
    const [friends, setFriends] = useState<Friend[]>([]);
    
    const [selectedConversation, setSelectedConversation] = useState<number | null>(null);
    const [showCreateGroupForm, setShowCreateGroupForm] = useState<boolean>(false);
    const [showAddFriendForm, setShowAddFriendForm] = useState<boolean>(false);
    const [selectedFriends, setSelectedFriends] = useState<Friend[]>([]);
    const [filteredFriends, setFilteredFriends] = useState<Friend[]>(friends); // State to store filtered friends
    const [newMessage, setNewMessage] = useState<string>(''); // State for new message input
    const [messages, setMessages] = useState<Message[]>([]);
    const [groupName, setGroupName] = useState<string>(''); // State for group name
    


    const fetchConversations = async () => {
        try {
            const response = await api.Get('/conversations');
            const data = await response.json();
            setConversations(data); // Update the conversations state
        } catch (error) {
            console.error('Error fetching conversations:', error);
        }
    };

    const fetchFriends = async () => {
        try {
            const response = await api.Get('/users/friends');
            const data: Friend[] = await response.json();
            console.log(data);
            setFriends(data);
        } catch (error) {
            console.error('Error fetching friends:', error);
        }
    };

    const handleNavigate = () => {
        navigate('/main-panel'); // Navigate to the MainPanel page
    };

    const handleSelectConversation = (id: number) => {
        setSelectedConversation(id);
        // Simulated messages for now
        setMessages([
            { id: 1, sender: 'User 1', content: 'Hello!, How are you?', likedByUser: false, likes: 0 },
            { id: 2, sender: 'User 2', content: 'Hi there!', likedByUser: false, likes: 0 },
            { id: 3, sender: 'User 1', content: 'I am doing great!', likedByUser: false, likes: 0 },
        ]);
    };

    const handleAddFriendToConversation = async () => {
        if (selectedConversation === null) return;
        try {
            console.log(selectedFriends);
            const body = selectedFriends.map((friend) => ({ "email": friend.email }));
            console.log (body);
            const response = await api.Post(`/conversations/add/${selectedConversation}`, body);
            if (response.ok) {
                fetchConversations();
            }
        } catch (error) {
            console.error('Error adding friends to conversation:', error);
        }
        setSelectedFriends([]); // Clear the selected friends after adding
    };

    const handleCreateGroupClick = () => {
        fetchFriends(); // Fetch friends list
        setShowCreateGroupForm(true); // Show the form to select friends
    };

    const handleAddFriendClick = () => {
        fetchFriends(); // Fetch friends list
        setShowAddFriendForm(true); // Show the form to add friends to the conversation
    };

    const handleSelectFriend = (friend: Friend) => {
        setSelectedFriends((prev) =>
            prev.some((f) => f.id === friend.id) ? prev.filter((f) => f.id !== friend.id) : [...prev, friend]
        );
    };

    const handleCreateGroup = async () => {
        selectedFriends.forEach((friend) => console.log(friend.id));
        try{
            const body = selectedFriends.map((friend) => ({ "email": friend.email }));
            console.log(body);
            const response = await api.Post(`/conversations/create/${groupName}`, body);
            if (response.ok)
            {
                const data = await response.json();
                setConversations([...conversations, data]);
            }
        }
        catch(error){
            console.error('Error creating group:', error);
        }
        setShowCreateGroupForm(false); // Close the form after group creation
    };

    const handleCancelGroupCreation = () => {
        setShowCreateGroupForm(false); // Close the form without creating the
    };

    const handleSearchFriend = (searchTerm: string) => {
        const filtered = friends.filter((friend) =>
            friend.name.toLowerCase().includes(searchTerm.toLowerCase())
        );
        setFilteredFriends(filtered); // Update the filtered friends list
    };

    const handleSendMessage = (e: React.FormEvent) => {
        e.preventDefault();
        if (newMessage.trim() === '') return;

        const newMessageData = {
            id: messages.length + 1,
            sender: user?.name || 'You',
            content: newMessage,
            likedByUser: false,
            likes: 0,
        };

        setMessages([...messages, newMessageData]);
        setNewMessage(''); // Clear the input
    };

    const handleLikeMessage = (messageId: number) => {
        setMessages((prevMessages) =>
            prevMessages.map((msg) =>
                msg.id === messageId
                    ? {
                          ...msg,
                          likedByUser: !msg.likedByUser,
                          likes: msg.likedByUser ? msg.likes - 1 : msg.likes + 1,
                      }
                    : msg
            )
        );
    };

    const handleLeaveGroupClick = async () => {
        if (selectedConversation !== null) {
            const response = await api.Delete(`/conversations/leave/${selectedConversation}`);
            if (!response.ok) {
                console.error('Error leaving group:', response.statusText);
                return;
            }
            const updatedConversations = conversations.filter((conv) => conv.id !== selectedConversation);
            setConversations(updatedConversations);
            setSelectedConversation(null);
        }
    };

    const handleCancelFriendAdding = () => {
        setShowAddFriendForm(false); // Close the form without adding friends
    };

    useEffect(() => {
        fetchConversations();
        fetchFriends();
        setFilteredFriends(friends);
    }, []);

    
    return (
        <div className="conversations-container">
            <header className="conversations-header">
                <img
                    src="https://media1.tenor.com/m/7aEyY3k7WycAAAAC/yap-yapping.gif"
                    alt="React Logo"
                    className="home-logo"
                />
                <h1 className="home-title">YapYap</h1>
            </header>
            <div className="conversations-content">
                <div className="conversations-sidebar">
                    <h2 className="conversations-sidebar-title">Conversations</h2>

                    <button
                        onClick={handleCreateGroupClick}
                        className="create-group-button"
                        style={{ marginTop: '20px' }} // You can adjust the style here as needed
                    >
                        Create Group
                    </button>

                    <ul className="conversations-list">
                        {conversations.map((conversation) => (
                            <li
                                key={conversation.id}
                                className={`conversation-item ${
                                    selectedConversation === conversation.id ? 'active' : ''
                                }`}
                                onClick={() => handleSelectConversation(conversation.id)}
                            >
                                {conversation.name}
                            </li>
                        ))}
                    </ul>
                </div>

                <div className="messages-container">
                    <h2 className="messages-title">Messages</h2>
                    {selectedConversation === null ? (
                        <p>Select a conversation to view messages</p>
                    ) : (
                        <>
                            <div className="message-history">
                                {messages.map((message) => (
                                    <div key={message.id} className="message-item">
                                        <strong>{message.sender}:</strong> {message.content}
                                        <div className="message-actions">
                                            <span
                                                className="like-counter"
                                                onClick={() => handleLikeMessage(message.id)}
                                            >
                                                {message.likedByUser ? '❤️ ' : '🤍 '} {message.likes} {message.likes === 1 ? 'Like' : 'Likes'}
                                            </span>
                                        </div>
                                    </div>
                                ))}
                            </div>
                            <form className="message-form" onSubmit={handleSendMessage}>
                                <input
                                    type="text"
                                    placeholder="Type a message"
                                    value={newMessage}
                                    onChange={(e) => setNewMessage(e.target.value)}
                                />
                                <button type="submit">Send</button>
                            </form>
                        </>
                    )}
                </div>

                <div className="group-members-container">
                    {selectedConversation !== null && (
                        <div className="group-members-list">
                            <button
                                onClick={handleLeaveGroupClick}
                                className="leave-group-button"
                            >
                                Leave Group
                            </button>
                            <button
                                onClick={handleAddFriendClick}
                                className="add-friend-to-conversation-button"
                            >
                                Add Friend
                            </button>
                            <div className="group-members-list-container">
                                {conversations
                                    .find((conv) => conv.id === selectedConversation)
                                    ?.members.map((member: Friend) => (
                                        <div key={member.id} className="group-member-item">
                                            {member.name}
                                        </div>
                                    ))}
                            </div>
                        </div>
                    )}
                </div>
            </div>

            <div className="navigation-button-container">
                <button onClick={handleNavigate} className="main-panel-button">
                    Go to Main Panel
                </button>
            </div>

            {showCreateGroupForm && (
                <div className="add-friend-form-overlay">
                    <div className="add-friend-form">
                        <h3>Create Group</h3>
                        <input
                            type="text"
                            placeholder="Group name"
                            className="search-friend-input"
                            onChange={(e) => setGroupName(e.target.value)}
                        />
                        <h3>Select Friends to Add</h3>
                        <input
                            type="text"
                            placeholder="Search friends"
                            className="search-friend-input"
                            onChange={(e) => handleSearchFriend(e.target.value)}
                        />
                        <ul className="friend-list">
                            {filteredFriends.map((friend) => (
                                <li
                                    key={friend.id}
                                    className={`friend-item ${
                                        selectedFriends.some((f) => f.id === friend.id) ? 'selected' : ''
                                    }`}
                                    onClick={() => handleSelectFriend(friend)}
                                >
                                    {friend.name}
                                </li>
                            ))}
                        </ul>
                        <div className="form-buttons">
                            <button onClick={handleCreateGroup} className="create-group-button">
                                Confirm
                            </button>
                            <button onClick={handleCancelGroupCreation} className="cancel-create-group-button">
                                Cancel
                            </button>
                        </div>
                    </div>
                </div>
            )}

            {showAddFriendForm && (
                <div className="add-friend-form-overlay">
                    <div className="add-friend-form">
                        <h3>Select Friends to Add</h3>
                        <input
                            type="text"
                            placeholder="Search friends"
                            className="search-friend-input"
                            onChange={(e) => handleSearchFriend(e.target.value)}
                        />
                        <ul className="friend-list">
                            {filteredFriends.map((friend) => (
                                <li
                                    key={friend.id}
                                    className={`friend-item ${
                                        selectedFriends.some((f) => f.id === friend.id) ? 'selected' : ''
                                    }`}
                                    onClick={() => handleSelectFriend(friend)}
                                >
                                    {friend.name}
                                </li>
                            ))}
                        </ul>
                        <div className="form-buttons">
                            <button onClick={handleAddFriendToConversation} className="create-group-button">
                                Confirm
                            </button>
                            <button onClick={handleCancelFriendAdding} className="cancel-create-group-button">
                                Cancel
                            </button>
                        </div>
                    </div>
                </div>
            )}
        </div>
    );
};

export default Conversations;
