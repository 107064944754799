import React, { useState } from 'react';
import { useAuth } from '../context/AuthContext';  // Import the useAuth hook
import '../ui/Posts.css';
import { useNavigate } from 'react-router-dom';

// Simulating posts data
const mockPosts = [
    {
        id: 1,
        content: "This is my first post! Excited to be here.",
        posterName: "John Doe",
        likes: 10,
        dislikes: 2,
        comments: [
            { id: 1, content: "Great post, John!", commenterName: "Alice" },
            { id: 2, content: "I agree with you, looking forward to more posts.", commenterName: "Bob" },
        ],
    },
    {
        id: 2,
        content: "Had a wonderful day at the park today.",
        posterName: "Jane Smith",
        likes: 15,
        dislikes: 3,
        comments: [
            { id: 3, content: "That sounds amazing! I love the park.", commenterName: "Tom" },
        ],
    },
];


const Posts: React.FC = () => {
    const { user } = useAuth();  // Get the signed-in user info from AuthContext
    const [posts, setPosts] = useState(mockPosts);
    const navigate = useNavigate();
    const handleNavigate = () => {
        navigate('/main-panel'); // Navigate to the MainPanel page
    };
    // Handle like button click
    const handleLike = (postId: number) => {
        setPosts(posts.map(post =>
            post.id === postId ? { ...post, likes: post.likes + 1 } : post
        ));
    };

    // Handle dislike button click
    const handleDislike = (postId: number) => {
        setPosts(posts.map(post =>
            post.id === postId ? { ...post, dislikes: post.dislikes + 1 } : post
        ));
    };

    return (
        <div className="posts-container">
            <header className="posts-header">
                <div className="logo-container">
                    <img src="https://media1.tenor.com/m/7aEyY3k7WycAAAAC/yap-yapping.gif" alt="Logo" className="logo" />
                    <h1 className="app-title">YapYap</h1>
                </div>
                {user ? (
                    <div className="user-info">
                        <strong>{user.name}</strong><br/>
                        <span>{user.email}</span>
                        <button onClick={handleNavigate} className="navigate-button">
                            Go to Main Panel
                        </button>
                    </div>
                ) : (
                    <div className="user-info">
                        <span>Please log in</span>
                    </div>
                )}
            </header>
            <h2 className="posts-title">Posts</h2>
            <p className="posts-subtitle">Here are your posts:</p>
            {posts.map((post) => (
                <div key={post.id} className="post-card">
                    <div className="post-header">
                        <strong>{post.posterName}</strong>
                    </div>
                    <div className="post-content">
                        <p>{post.content}</p>
                    </div>
                    <div className="post-actions">
                        <button onClick={() => handleLike(post.id)} className="like-button">
                            👍 {post.likes}
                        </button>
                        <button onClick={() => handleDislike(post.id)} className="dislike-button">
                            👎 {post.dislikes}
                        </button>
                    </div>
                    <div className="post-comments">
                        <h4>Comments:</h4>
                        {post.comments.map(comment => (
                            <div key={comment.id} className="comment">
                                <strong>{comment.commenterName}:</strong>
                                <p>{comment.content}</p>
                            </div>
                        ))}
                    </div>
                </div>
            ))}
        </div>
    );
};

export default Posts;
