// ProtectedRoute.tsx
import React, { ReactNode } from 'react';
import { Navigate } from 'react-router-dom';
import { useAuth } from '../context/AuthContext'

interface ProtectedRouteProps {
    children: ReactNode;  // Explicitly type the 'children' prop as ReactNode
}

const ProtectedRoute: React.FC<ProtectedRouteProps> = ({ children }) => {
    const { user } = useAuth();  // Assuming 'user' holds the authentication status

    // If the user is authenticated, render children (the protected route)
    // If not authenticated, redirect to the home page
    if (!user) {
        return <Navigate to="/" />;
    }

    // Return children if authenticated, else redirect
    return <>{children}</>;
};

export default ProtectedRoute;
