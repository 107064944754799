// src/pages/MainPanel.tsx
import React from 'react';
import { useNavigate } from 'react-router-dom';
import { useAuth } from '../context/AuthContext';
import '../ui/MainPanel.css';

const MainPanel: React.FC = () => {
    const { logout } = useAuth();
    const navigate = useNavigate(); // Hook for programmatic navigation

    // Function to handle navigation to a specific route
    const handleNavigation = (route: string) => {
        navigate(route); // Programmatically navigate to the route
    };

    return (
        <div className="main-panel-container">
            <header className="main-header">
                <img src="https://media1.tenor.com/m/7aEyY3k7WycAAAAC/yap-yapping.gif" alt="React Logo" className="home-logo"/>
                <h1 className="home-title">YapYap</h1>
            </header>
            <nav className="main-panel-nav">
                <ul>
                    <li onClick={() => handleNavigation('/friend-list')}>Friend List</li>
                    <li onClick={() => handleNavigation('/conversations')}>Conversations</li>
                    <li onClick={() => handleNavigation('/posts')}>Posts</li>
                    <li onClick={() => handleNavigation('/profile')}>Profile</li>
                </ul>
            </nav>
            <button className="main-panel-logout" onClick={logout}>Logout</button>
        </div>
    );
};

export default MainPanel;
