import React, { createContext, useState, useContext, ReactNode, useEffect } from 'react';
import { User } from '../types/User'; // Import User type


interface AuthContextType {
    user: User | null;
    login: (email: string, password: string) => Promise<void>;
    logout: () => void;
    isAuthenticated: boolean; // To track authentication status
}

interface AuthProviderProps {
    children: ReactNode;  // Add children prop with type ReactNode
}

const AuthContext = createContext<AuthContextType | undefined>(undefined);

export const AuthProvider: React.FC<AuthProviderProps> = ({ children }) => {
    const [user, setUser] = useState<User | null>(null);
    const [isAuthenticated, setIsAuthenticated] = useState<boolean>(false);



    const login = async (email: string, password: string): Promise<void> => {
        try {
            // Send login request
            const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/users/login`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    email: email,
                    password: password,
                }),
            });

            // If the response is not OK (status code 2xx), throw an error
            if (!response.ok) {
                throw new Error('Login failed');
            }

            // Since response only contains the token (as plain text)
            const token = await response.text(); // Extract the token (plain text)

            if (!token) {
                throw new Error('No token received');
            }

            // Store the token in localStorage
            localStorage.setItem('authToken', token);

            // After login, fetch user data using the token
            await fetchUserData(email); // Call to fetch user data using the token
        } catch (error: any) {
            console.error(error);
            throw new Error(error.message || 'An error occurred during login');
        }
    };

    const fetchUserData = async (email: string): Promise<void> => {
        try {
            // Fetch user data using the stored token
            const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/users/${email}`, {
                headers: {
                    'Authorization': `Bearer ${localStorage.getItem('authToken')}`,
                },
            });

            if (!response.ok) {
                throw new Error('Failed to fetch user data');
            } else {
                const userData = await response.json(); // Parse the user data response
                const user: User = {id: userData.id, email: userData.email, name: userData.name}; // Create a user object
                setUser(user); // Set the user state
                setIsAuthenticated(true); // Set authentication status
            }
        } catch (error) {
            console.error(error);
            setIsAuthenticated(false);
            setUser(null);
        }
    };


    const logout = () => {
        localStorage.removeItem('authToken'); // Remove token from localStorage
        setUser(null); // Clear user state
        setIsAuthenticated(false); // Update authentication status
    };

    return (
        <AuthContext.Provider value={{ user, login, logout, isAuthenticated }}>
            {children}  {/* This renders any children passed to the AuthProvider */}
        </AuthContext.Provider>
    );
};

export const useAuth = (): AuthContextType => {
    const context = useContext(AuthContext);
    if (!context) {
        throw new Error('useAuth must be used within an AuthProvider');
    }
    return context;
};
